import React, { useEffect } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { navigate } from 'gatsby';
import useFirebase from '../utils/useFirebase'
import axios from 'axios'

import { setUser, isLoggedIn } from "../utils/auth"

const Login = () => {
  const firebase = useFirebase()
  if (isLoggedIn()) {
    navigate(`/app/profile`)
  }

  function getUiConfig(auth) {
    return {
      signInFlow: 'popup',
      signInOptions: [
        firebase.firebase_.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.firebase_.auth.EmailAuthProvider.PROVIDER_ID
      ],
      // Terms of service url.
      tosUrl: 'https://slickbot.io',
      // Privacy policy url.
      privacyPolicyUrl: 'https://slickbot.io',
      callbacks: {
        signInSuccessWithAuthResult: (result) => {
          console.log(result)
          setUser(result.user);
          navigate('/app/setup/');
        }
      }
    };
  }

  return (
    <>
      {firebase && <StyledFirebaseAuth uiConfig={getUiConfig()} firebaseAuth={firebase.auth()} />}
    </>
  );

}
export default Login
